import React from "react";
import { BsChatLeftText } from "react-icons/bs";
import { IconContext } from "react-icons";

const Paso3Home = () => {
  return (
    <>
      <IconContext.Provider
        value={{ color: "red", className: "global-class-name", size: "6em" }}
      >
        <div className=" home-jumbotron d-flex flex-column text-center justify-content-center align-items-center ">
          <BsChatLeftText className="mt-5" />
          <div>
            <p className="fs-2">MANDANOS MENSAJE</p>
            <p className="fs-5">
              Manda un whatsapp o mensaje de texto al numero: +52 221 652 4006.{" "}
              <br />
              El mensaje debe de incluir:
              <ul className="fs-5">
                <li>Tu nombre completo seguido de: "USAEXPRESS"</li>
                <li>El comprobante de tu pedido</li>
              </ul>
            </p>
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
};

export default Paso3Home;
