import { React, useState } from "react";
import { AiOutlineUp, AiOutlineDown } from "react-icons/ai";

const Faq = () => {
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected == i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  return (
    <>
      <>
        <body className="home-wrapper-1 py-5">
          <div className="container-xxl py-3">
            <img
              className="home-image"
              src={require("../images/faq.png")}
              alt=""
            />
          </div>

          <div className="container-xxl py-3">
            <div className="accordion p-3">
              {preguntas.map((item, i) => (
                <div className="item">
                  <div className="pregunta d-flex">
                    <p className="tittle" onClick={() => toggle(i)}>
                      {item.question}
                    </p>
                    <span>
                      {selected === i ? (
                        <AiOutlineUp className="m-3" />
                      ) : (
                        <AiOutlineDown className="m-3" />
                      )}
                    </span>
                  </div>
                  <div className={selected === i ? "content show" : "content"}>
                    {item.answer}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </body>
      </>
    </>
  );
};

const preguntas = [
  {
    question: "En la información de pedido. ¿Qué datos van?",
    answer:
      "Tu nombre y apellido, seguido de USAEXPRESS (Ejemplo: Edgar Ramirez USAEXPRESS)",
  },
  {
    question: "Para el seguimiento del pedido. ¿Qué datos van?",
    answer:
      "En el número de teléfono -221 652 4006- de USA EXPRESS y tu correo para el seguimiento",
  },
  {
    question: "¿A qué domicilio se envía el pedido?",
    answer: "5034 Hampton St Vernon CA 90058 Los Ángeles",
  },
  {
    question: "¿Cuáles son los horarios laborales?",
    answer:
      "De Lunes a Viernes de 10:00am a 6:00pm y Sábados de 10:00am a 4:00pm",
  },
  {
    question: "Después de realizar mi compra ¿Qué tengo que hacer?",
    answer:
      "Manda tu comprobante por medio de WhatsApp -221 652 4006- de USA EXPRESS",
  },
  {
    question: "¿Si no vivo en Puebla me pueden enviar mi paquete?",
    answer: "Se puede reenviar por paquetería o mandar a recolectar el paquete",
  },
  {
    question: "¿Puedo enviar a alguien a recoger mi paquete?",
    answer:
      "Si, solo pediremos una carta poder firmada, con una impresión de tu INE para poder entregarlo",
  },
  {
    question: "Si es mi primer compra. ¿Qué tengo que hacer?",
    answer:
      "Registrate con nosotros por medio de WhatsApp -221 652 4006- de USA EXPRESS",
  },
  {
    question: "¿Tengo seguro de envío en mi pedido?",
    answer:
      "Si, nos encargamos del 100% del costo de tu producto en caso de extravío",
  },
  {
    question: "¿Existe algún monto de compras mínimas?",
    answer: "El monto mínimo es de $150usd",
  },
  {
    question: "¿De qué nos encargamos nosotros?",
    answer: "Envío internacional, gestión aduanal y entrega final",
  },
  {
    question: "¿El precio del envío incluye los gastos de aduana?",
    answer: "Si, el precio de envío incluye todo hasta la hora de la entrega",
  },
  {
    question: "¿Cuál es el costo de envío?",
    answer: "El 25% del subtotal de tu factura antes del shipping y tax",
  },
  {
    question: "¿El tipo de cambio en dólares es el vigente?",
    answer: "Si, el tipo de cambio en dolares es el actual",
  },
  {
    question: "¿Qué pasa si no alcanzo los $150 usd?",
    answer: "Se cobrara un único monto de $200 pesos mx por el uso de aduana",
  },
];

export default Faq;
