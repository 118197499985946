import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import { LuPackageCheck } from "react-icons/lu";

const Home = () => {
  return (
    <body className="home-wrapper-1 py-5">
      <div className="container-xxl py-3">
        <img
          className="home-image"
          src={require("../images/home.jpg")}
          alt=""
        />
      </div>

      <div className="container-xxl py-3">
        <div className="home-jumbotron d-flex flex-column flex-md-row justify-content-center px-5">
          <div>
            <img
              className="jumbo"
              src={require("../images/jumbo.png")}
              alt="jumbo"
            />
          </div>

          <div className="d-flex flex-column text-center justify-content-center align-items-center">
            <p>!USA EXPRESS Facilita tus compras!</p>
            <p>Compra desde USA y nosotros te lo enviamos</p>
            <ul>
              <li>
                <LuPackageCheck /> Pagos Seguros
              </li>
              <li>
                <LuPackageCheck /> Envios Seguros
              </li>
              <li>
                <LuPackageCheck /> Compras Seguras
              </li>
            </ul>
          </div>
        </div>

        <a
          href="https://wa.me/message/TYHVMN5POHSNA1"
          className="register-button my-2 p-3 d-flex justify-content-center"
        >
          Registrate Ahora
        </a>
      </div>
      <div className="container-xxl py-5">
        <div className="row">
          <div className="home-video d-flex justify-content-center">
            <iframe
              width="100%"
              height="500vw"
              src="https://www.youtube.com/embed/aHVZSe-EWAQ"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="row">
          <div className="steps mb-5 d-flex justify-content-center">
            Sigue estos pasos:
          </div>
          <div className="homelist d-flex flex-wrap justify-content-center">
            <NavLink
              to="home/step1"
              className="step-button m-2 d-flex justify-content-center align-items-center"
            >
              1
            </NavLink>
            <NavLink
              to="home/step2"
              className="step-button m-2 d-flex justify-content-center align-items-center"
            >
              2
            </NavLink>
            <NavLink
              to="home/step3"
              className="step-button m-2 d-flex justify-content-center align-items-center"
            >
              3
            </NavLink>
            <NavLink
              to="home/step4"
              className="step-button m-2 d-flex justify-content-center align-items-center"
            >
              4
            </NavLink>
          </div>
          <div className="container-xxl py-3">
            <div className="row">
              <div className="p-3">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};

export default Home;
