import React from "react";
import { CiDeliveryTruck } from "react-icons/ci";

const Contact = () => {
  return (
    <body className="home-wrapper-1 py-5">
      <div className="container-xxl py-3">
        <img
          className="home-image"
          src={require("../images/contact.png")}
          alt=""
        />
      </div>

      <div className="container-xxl py-3">
        <div className="home-jumbotron d-flex flex-column flex-md-row justify-content-center align-items-md-center">
          <div className="d-flex flex-column text-center p-3">
            <p className="fs-3 ps-3">
              Primero a Los Ángeles, luego a tus manos.
            </p>
            <p className="fs-5 ps-3">
              El paquete primero llega a nuestro centro de operaciones en Los
              Ángeles, luego lo enviamos a nuestro centro de reparto en Puebla
              donde podrás recoger tu paquete. Recuerda incluir después de tu
              nombre "USAEXPRESS"
            </p>

            <p className="bg-secondary text-white fs-6 m-3 ps-3">
              JUAN RODRIGUEZ (EJEMPLO) – USAEXPRESS <br /> 5034 HAMPTON ST.
              VERNON, LOS ÁNGELES, CA, 90058 <br /> Número de teléfono de USA
              EXPRESS: +52 221 652 4006
            </p>
            <p className="fs-3 ps-3">Nosotros nos encargamos del resto:</p>
            <ul className="fs-5 ps-3">
              <li>
                <CiDeliveryTruck /> Envío Internacional
              </li>
              <li>
                <CiDeliveryTruck /> Gestión Aduanal
              </li>
              <li>
                <CiDeliveryTruck /> Entrega
              </li>
            </ul>
          </div>
          <div className="d-flex justify-content-center">
            <img
              className="phone-image"
              src={require("../images/phone.png")}
              alt="phone"
            />
          </div>
        </div>
      </div>
    </body>
  );
};

export default Contact;
